import React, { useEffect, useState } from 'react'
import FileViewer from 'react-file-viewer';

// Material UI components
import { Grid, Tooltip, AppBar, Toolbar, Dialog, Slide, IconButton, DialogContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import GetAppIcon from '@material-ui/icons/GetApp';

import { dic } from '../../_constants/dictionary.constants';

// Personal functions
import { exportsServices } from '../../_services';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const XLSXExportViewer = (props) => {

  const { searchParams, title, functionRequired, open, setOpen } = props
  
  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState(null)

  const downloadXlsx = () => {
    let link = document.createElement('a');
    link.href = file;
    link.download= title+".xlsx";
    link.click();
  }

  useEffect(() => {
    const getDataXlsx = () => {
        switch (functionRequired){
            case 'getTurnoverBySemesters':
                exportsServices.getTurnoverBySemesters(searchParams)
                    .then(resp => {
                    const fileURL = URL.createObjectURL(new Blob([resp], {type: "application/xlsx"}))
                    setFile(fileURL)
                    setLoading(false);
                })
                break;
            case 'getStatisticByYear':
                exportsServices.getStatisticByYear(searchParams, 'xlsx')
                    .then(resp => {
                    const fileURL = URL.createObjectURL(new Blob([resp], {type: "application/xlsx"}))
                    setFile(fileURL)
                    setLoading(false);
                })
                break;
            default:

        }
    }
    
    if (searchParams && open)
        getDataXlsx()
  }, [searchParams, open, functionRequired]);

  const handleClose = () => {
    setFile(null)
    setLoading(true)
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="false"
    >
      <AppBar position="fixed" color="primary" style={{top: 0}}>
            <Toolbar style={{backgroundColor: "grey"}}>
              
              <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item>
                    {title}
                </Grid>
              </Grid>
              {!loading &&
                <Tooltip title={dic.DOWNLOAD} placement="top" arrow>
                  <IconButton onClick={downloadXlsx} style={{color: "white"}}>
                      <GetAppIcon />
                  </IconButton>
                </Tooltip>
              }
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <Close />
              </IconButton>
            </Toolbar>
        </AppBar>
        <DialogContent>
          <div style={{marginTop: "120px", width: "100%", textAlign: "center"}}>
            {loading && <span>{dic.LOADING}...</span>}
          </div>
          {file ?  
              <FileViewer
                fileType="xlsx"
                filePath={file}
              />
          : null}
          
        </DialogContent>
      
    </Dialog>
  )
}

export default XLSXExportViewer