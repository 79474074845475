import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import itLocale from "date-fns/locale/it";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Material UI components
import { 
    Card, CardHeader, CardContent, Grid, Button, CardActions,
    FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';

import { PictureAsPdf, GridOn } from '@material-ui/icons';

import { dic } from '../../_constants'

import XLSXExportViewer from './XLSXExportViewer'   
import PDFViewer from './PDFViewer'

const ActorSelection = (props) => {
    const {setSearchParams, searchParams, actors} = props

    const actor_id = actors.findIndex(actor => actor.id === searchParams.actor_id);
    // console.log(actors.findIndex(actor => actor.id === searchParams.actor_id));
    return (
        <FormControl variant="outlined" style={{width: "100%"}}>
          <InputLabel id="actor-select-outlined-label">{dic.ACTORS}</InputLabel>
          <Select
            labelId="actor-select-outlined-label"
            id="actor-select-outlined"
            label={dic.ACTORS}
            value={actor_id > 0 ? actor_id : 1}
            onChange={(e) => setSearchParams({...searchParams, actor_id: actors[e.target.value].id})}
          >
            {actors.map((actor, key) => key > 0 && <MenuItem key={key} value={key}>{actor.corporate_name}</MenuItem>)}
          </Select>
        </FormControl>
    )
}

const StatisticByYear = (props) => {
    const { actors } = props;
    const defaultDateParams = new Date();
    defaultDateParams.setFullYear(defaultDateParams.getFullYear()-1);

    const defaultYear = defaultDateParams.getFullYear();
    const [searchParams, setSearchParams] = useState({year: defaultYear, actor_id: actors[1].id});
    
    const [date, setDate] = useState(defaultDateParams);
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        setSearchParams(prevData => ({...prevData, year: date.getFullYear()}))
    }, [date, setSearchParams])

    const [openExportViewer, setOpenExportViewer] = useState(null)

    return (
        <Card>
        <CardHeader title="Statistiche per prestazioni"></CardHeader>
            <CardContent>
            <Grid container spacing={2} justifyContent="flex-start">
                <Grid item xs={12} sm={10}>
                    {(actors && searchParams.actor_id) &&
                        <ActorSelection
                            searchParams={ searchParams }
                            setSearchParams={ setSearchParams }
                            actors={ actors }
                        />
                    }
            </Grid>
                <Grid item xs={12} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
                    <DatePicker
                    label={dic.YEAR}
                    variant="inline"
                    inputVariant="outlined"
                    openTo="year"
                    views={["year"]}
                    value={date}
                    onChange={setDate}
                    autoOk
                    />
                </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </CardContent>
        <CardActions>
            <Grid container spacing={2} justifyContent="center" style={{padding: 30}}>
                <Grid item xs={12} sm={12}>
                <Button startIcon={<GridOn />} onClick={() => setOpenExportViewer(true)} variant="outlined" color="primary">
                    {dic.XLSX}
                </Button>
                <XLSXExportViewer 
                    searchParams={searchParams}
                    title={"Statistica "+searchParams.year}
                    open={openExportViewer}
                    setOpen={setOpenExportViewer}
                    functionRequired='getStatisticByYear'
                />
                <Button startIcon={<PictureAsPdf />} onClick={() => setOpen(true)} variant="outlined" color="primary">
                    {dic.PDF}
                </Button>
                <PDFViewer
                    open={open}
                    data={searchParams}
                    setOpen={setOpen}
                />
                </Grid>
            </Grid>
        </CardActions>
        </Card>
    )
}

export default StatisticByYear