import { setCall, handleResponse } from '../_helpers';

export const exportsServices = {
    getTurnoverBySemesters,
    getStatisticByYear
}


async function getTurnoverBySemesters(searchParams) {
  let requestOptions = {
      method: 'POST',
      body: setCall('exports', 'get_turnover_by_semesters', searchParams, "application/xlsx"),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"xlsx")

  return json;
}

async function getStatisticByYear(searchParams, fileType) {
  let fT = fileType ? fileType : "pdf";
  let requestOptions = {
      method: 'POST',
      body: setCall(
        'exports', 
        'get_statistic_by_year_'+fT, 
        searchParams, 
        "application/"+fT
      ),
      headers: {"Content-type": "application/json; charset=UTF-8" }
  }
  
  const response = await fetch('/api/', requestOptions);
  const json = await handleResponse(response, () => {} ,"xlsx")

  return json;
}
